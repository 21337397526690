import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';

import rootReducer from './reducers';
import {createApiMiddleware} from './auth/middleware';

const apiMiddleware = createApiMiddleware('auth');
const loggerMiddleware = createLogger();

export const store = createStore(
    rootReducer,
    applyMiddleware(
        apiMiddleware,
        loggerMiddleware
    )
);