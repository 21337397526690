import React, {Component} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import {Provider} from 'react-redux'
import PrivateRoute from './containers/PrivateRoute';
import './App.scss';

import {store} from './app/store';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Pages
const DefaultLayout = React.lazy(() => import('./components/DefaultLayout'));
const LoginPage = React.lazy(() => import('./components/Pages/LoginPage'));
const Register = React.lazy(() => import('./components/Pages/Register'));
const Page404 = React.lazy(() => import('./components/Pages/Page404'));
const Page500 = React.lazy(() => import('./components/Pages/Page500'));

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={props => <LoginPage {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <PrivateRoute exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <PrivateRoute exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <PrivateRoute path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
