import React from 'react';
import {connect} from 'react-redux'
import {Route, Redirect} from 'react-router-dom';
import {isAuthenticated} from '../../app/reducers';

const PrivateRoute = ({isAuthenticated, render, ...rest}) => {
  return <Route {...rest} render={props => (
    isAuthenticated
      ? render(props)
      : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
};

const mapStateToProps = (state) => ({
  isAuthenticated: isAuthenticated(state)
})

export default connect(mapStateToProps, null)(PrivateRoute);
